import React from "react"
import Header from "../components/Header"
import Title from "../components/Title"
import Carousel from "../components/Carousel"
import { renderPictures } from "../utils/photos"

export default function Photos() {
  return (
    <>
      <Header />
      <Title />
      <Carousel>{renderPictures()}</Carousel>
    </>
  )
}
